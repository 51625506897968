<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tempo-ratio-logo />
        <h2 class="brand-text text-primary ml-1">Tempo<span>Ratio</span></h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Erstellen Sie einen Account bei TempoRatio.
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- username -->
              <b-form-group label="Benutzername" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Passwort">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="picture-upload"
                label="Benutzerbild hochladen"
              >
                <div class="d-flex">
                  <b-form-file
                    id="picture-upload"
                    v-model="image"
                    accept="image/*"
                    placeholder="Datei auswählen ..."
                    drop-placeholder="Datei hierher ziehen ..."
                  />
                  <b-button
                    v-if="hasImage"
                    variant="danger"
                    class="ml-1 text-nowrap"
                    @click="clearImage"
                    >Bild löschen</b-button
                  >
                </div>
                <b-img
                  v-if="hasImage"
                  :src="imageSrc"
                  class="mt-1 rounded-circle mx-auto avatar-preview"
                  fluid
                  block
                ></b-img>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Register privacy policy"
                  vid="register-privacy-policy"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Ich akzeptiere die
                    <b-link>Geschäftsbedingungen</b-link>
                    <!-- TODO: Link auf ne Seite mit Geschäftsbedingungen -->
                  </b-form-checkbox>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Account erstellen
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Sie haben bereits einen Account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Anmelden</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import TempoRatioLogo from '@/layouts/components/TempoRatioLogo.vue';
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import useJwt from '@/auth/jwt/useJwt';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';

localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    TempoRatioLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      image: null,
      imageSrc: null,
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/login/login-image.png'),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/login-image.png');
        return this.sideImg;
      }
      return this.sideImg;
    },
    hasImage() {
      return !!this.image;
    },
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          await useJwt
            .register({
              username: this.username,
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              const userID = response.data.user.id;
              localStorage.setItem('userID', userID);
              useJwt.setToken(response.data.jwt);
              // this.$ability.update(response.data.userData.ability);
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error);
            });

          if (this.image) {
            const formData = new FormData();
            formData.append('field', 'avatar');
            formData.append('ref', 'plugin::users-permissions.user');
            formData.append('refId', localStorage.getItem('userID'));
            formData.append('files', this.image, this.image.name);

            await this.$http
              .post('/upload', formData)
              .catch(err => console.log(err.response));
          }

          const userID = localStorage.getItem('userID');

          await this.$http
            .get(`/users/${userID}?populate=role,avatar`)
            .then(response => {
              const userData = {
                username: response.data.username,
                email: response.data.email,
                role: response.data.role.name,
                avatar: response.data.avatar
                  ? `${this.$dbBaseUrl}${response.data.avatar.formats.thumbnail.url}`
                  : null,
              };
              localStorage.setItem('userData', JSON.stringify(userData));
            });
          this.$router.replace('/').then(() => {
            const userData = getUserData();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Willkommen ${userData.fullName || userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Sie haben sich erfolgreich als ${userData.role} registriert.`,
              },
            });
          });
        }
      });
    },
    clearImage() {
      this.image = null;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
h2 span {
  color: #6e6b7b;
}
.avatar-preview {
  object-fit: cover;
  width: 20rem;
  height: 20rem;
}
</style>
